<template>
    <div class='sendcert'>
          <heade currentrStr="result"></heade>
    </div>
</template>
<script>
import heade from "@/components/header.vue";
export default {
      components: {
    heade,
  },
}
</script>
<style scoped>

</style>